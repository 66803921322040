<template>
	<div class="out">
		<div class="header">
			<div class="logo Title">

				<div class="Chinese"><img src="../assets/img/return_index.png" class="return1" alt=""
						@click="$router.back()" />{{ $t('main.newMange') }} <span v-show="is_caogao"
						style="color:#999">{{ $t('main.draft') }}</span></div>
			</div>
			<div class="right-icon">
				<img src="../assets/img/logo-icon.png" alt="">
				<div class="cc-wc">
					{{ $t('main.titleCenter') }}
				</div>
			</div>
		</div>
		<div class="content">
			<div class="zb-edit">
				<div class="zb-pic zb-edit-item" v-for="(item, index) in news_data" :key="index">
					<div class="zb-edit-item-top">
						<div class="title-ltfe">
							<img src="../assets/img/new-title.png" alt="">0{{ index + 1 }} {{ $t('main.newHead') }}<span
								v-show="item.status == 4" style="color:#999">{{ $t('main.draft') }}</span>：
							<img class="question" v-show="help.edit_news_title" @click="showHelpContent('edit_news_title')"
								src="../assets/img/question.png" alt="" srcset="" />
						</div>
						<div class="title-right">
							&lt;50{{ $t('main.l20cc') }}
						</div>
					</div>
					<div class="zb-edit-item-bottom">
						<input type="text" maxlength="50" :placeholder="$t('main.pinewtitle')" v-model="item.title">
					</div>
					<div class="err1" v-show="key_test == index + '.title'">{{ $t('main.csenword') }}{{ mgc }}</div>
					<div class="zb-edit-item-top">
						<div class="title-ltfe">
							<img src="../assets/img/news-link.png" alt="">{{ $t('main.newLink') }}
							<img class="question" v-show="help.edit_news_href" @click="showHelpContent('edit_news_href')"
								src="../assets/img/question.png" alt="" srcset="" />
						</div>
					</div>
					<div class="zb-edit-item-bottom textarea-box">
						<textarea v-model="item.url" :placeholder="$t('main.pinewlink')"></textarea>
					</div>
					<div class="err" v-show="key_test == index + '.url'">{{ $t('main.csenword') }}{{ mgc }}</div>
					<div class="err" v-if="item.status == 2">{{ item.opinion }}</div>
				</div>
				<div class="submint-info" @click="editData">
					{{ $t('main.submit') }}
				</div>
			</div>

		</div>
	</div>
</template>

<script>
import { Dialog } from 'vant';
export default {
	data() {
		return {
			popFlag: false,
			news_data: [{
				title: '',
				url: '',
				id: '',
				status: ''
			},
			{
				title: '',
				url: '',
				id: '',
				status: ''
			},
			{
				title: '',
				url: '',
				id: '',
				status: ''
			},
			{
				title: '',
				url: '',
				id: '',
				status: ''
			},
			{
				title: '',
				url: '',
				id: '',
				status: ''
			},
			{
				title: '',
				url: '',
				id: '',
				status: ''
			}
			],
			key_test: '',
			mgc: '',
			old_data: [],
			is_caogao: false,
			help: {
				edit_news_title: null,
				edit_news_href: null
			},
			isback: true,
			draftSasucc: ""
		}
	},


	mounted() {
		this.draftSasucc = this.$t('main.draftSasucc')
		if (!localStorage.getItem('helpinfo') || localStorage.getItem('helpinfo') == '{}') {
			this.help = {
				edit_news_title: null,
				edit_news_href: null
			}
		} else {
			if (this.$i18n.locale == 'en') {
				let data = JSON.parse(localStorage.getItem('helpinfo'))
				Object.keys(data).forEach((item) => {
					if (item.indexOf('_intl') != -1) {
						let label = item.split('_intl')[0]
						if (data[item]) {
							data[label] = data[item]
						}
					}
				})
				this.help = data
			} else {
				this.help = JSON.parse(localStorage.getItem('helpinfo'))
			}
		}

		this.loadData()
		// window.addEventListener('popstate', this.disableBrowserBack,false);
		// history.pushState(null, null, document.URL);
	},
	// destroyed(){
	// 	window.removeEventListener('popstate', this.disableBrowserBack,false);
	// },
	beforeRouteLeave(to, from, next) {
		if (to.name == 'master') {
			if (this.isback) {
				this.disableBrowserBack()
			}
		}
		next();
	},
	methods: {
		disableBrowserBack() {
			let flag = 0
			let post_arr = []
			for (let i = 0; i < this.news_data.length; i++) {
				if (JSON.stringify(this.news_data[i]) != JSON.stringify(this.old_data[i])) {
					flag = 1
					post_arr.push(this.news_data[i])
				}
			}
			if (!flag) {
				// this.$router.replace('/home')
			} else {
				let post_data = post_arr.filter(function (i) {
					return i.title != '' || i.url != ''
				})
				if (post_data.length == 0) {
					// this.$router.back()
					return
				}

				this.axios.post('/news/store', {
					list: post_data,
					status: 4,
				}).then((res) => {
					if (res.data.code == 0) {

						weui.toast(this.draftSasucc, {
							duration: 2000, callback: () => {
								// this.$router.replace('/home')
							}
						})
					} else {

						setTimeout(() => {
							if (res.data.msg.indexOf('敏感词') != -1) {
								weui.alert(this.$t('main.submitmg'))
								let msg = res.data.msg
								this.key_test = msg.split(' ')[0]

								this.mgc = msg.split('：')[1]
								return
							}
							weui.alert(res.data.msg)
						}, 500)

					}

				}).catch((err) => {


				})
			}
		},
		loadData() {
			this.axios.get('/news/myList', {

			}).then((res) => {
				
				res.data.data.list.forEach((v, i) => {
					if (v.status == 4) {
						this.is_caogao = true
					}
					this.news_data.splice(i, 1, v)
				})
				this.old_data = JSON.parse(JSON.stringify(this.news_data))




			}).catch((err) => {

			})
		},
		async editData() {
			let flag = 0
			let post_arr = []
			this.isback = false
			for (let i = 0; i < this.news_data.length; i++) {
				if (this.news_data[i].title) {
					let reg = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/;
					if (!reg.test(this.news_data[i].url)) {
						weui.alert(this.$t('main.penewadress'))
						return
					}
				}
				if (this.news_data[i].url) {
					if (!this.news_data[i].title) {
						weui.alert(this.$t('main.pinewtitle'),{ buttons: [{ label: this.$t('main.confIrm') }] })
						return
					}
				}
				if (JSON.stringify(this.news_data[i]) != JSON.stringify(this.old_data[i])) {
					flag = 1
					post_arr.push(this.news_data[i])
				} else if (this.news_data[i].status == 4) {
					post_arr.push(this.news_data[i])
				}
			}
			if (this.is_caogao) {
				flag = 1
			}
			if (flag == 0) {
				weui.alert(this.$t('main.inm'))
				return
			}
			let data_arr = this.news_data.filter(function (i) {
				return i.title != '' && i.url != ''
			})
			let old_data_arr = this.old_data.filter(function (i) {
				return i.title != '' || i.url != ''
			})
			if (data_arr.length < old_data_arr.length) {
				let del_arr = []
				del_arr = this.news_data.filter(function (i) {
					return !i.title && !i.url && i.id != ''
				})
				for (let i = 0; i < del_arr.length; i++) {
					await this.axios.post('/news/del', { type: 1, id: del_arr[i].id })
				}
			}

			if (data_arr.length == 0 && this.old_data.length == 0) {
				weui.alert(this.$t('main.penif'))
				return
			}
			if (data_arr.length == 0) {
				weui.toast(this.$t('main.submitsucc'), {
					duration: 2000, callback: () => {
						this.$router.replace('/home')
					}
				})
				return
			}

			let post_data = post_arr.filter(function (i) {
				return i.title != '' && i.url != ''
			})
			if (post_data.length == 0) {
				weui.toast(this.$t('main.submitsucc'), {
					duration: 2000, callback: () => {
						this.$router.replace('/home')
					}
				})
				return
			}

			this.axios.post('/news/store', {
				list: post_data,
			}).then((res) => {
				if (res.data.code == 0) {
					this.axios.post('/news/transArtistNewsInfo', { type: 1 })
					weui.toast(this.$t('main.submitsucc'), {
						duration: 2000, callback: () => {
							this.$router.replace('/home')
						}
					})
				} else {

					setTimeout(() => {
						if (res.data.msg.indexOf('敏感词') != -1) {
							weui.alert(this.$t('main.submitmg'))
							let msg = res.data.msg
							this.key_test = msg.split(' ')[0]

							this.mgc = msg.split('：')[1]
							return
						}
						weui.alert(res.data.msg)
					}, 500)

				}

			}).catch((err) => {


			})
		},
		deleteHttp(val) {

		},
		showHelpContent(key) {
			Dialog.alert({ message: this.help[key], confirmButtonColor: '#2d71d7', confirmButtonText: this.$t('main.confIrm') })
		},
	}
};
</script>

<style scoped>
.out {
	padding: 0 0.15rem;
	min-height: 100vh;
	box-sizing: border-box;
	box-sizing: border-box;
	background: url(../assets/img/index_bg.png) no-repeat center;
	background-size: cover;
	background-attachment: fixed;
}

.header {
	display: flex;
	justify-content: space-between;
	/* line-height: 0; */
	margin-bottom: 0rem;
	margin-bottom: 0rem;
	margin: 0 -0.15rem;
	padding: 0 0.2rem;
	align-items: center;
	margin-bottom: 0.1rem;
	box-shadow: 0px 5px 15px -7px rgba(0, 0, 0, 0.3);
	padding-bottom: 0.09rem;
	padding-top: 0.05rem;
	background: #FFFFFF;
}

.Title {
	font-size: 0.16rem;
	position: relative;
}

.English {
	color: #d6eedc;
	position: absolute;
	top: 0.1rem;
	left: 0.15rem;
	width: max-content;
}

.Chinese {
	color: #1b6a21;
	/* top: -0.2rem; */
	left: 0;
	position: relative;
	text-align: left;
	font-size: 0.18rem;
}

.cc-wc {
	font-size: 0.1rem;
	color: #e9630a;
}

.right-icon {
	/* width: 1rem; */
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.right-icon img {
	display: block;
	width: 100%;
	height: 100%;
	width: 0.65rem;
}

.zb-edit {
	font-size: 0.16rem;
	padding-bottom: 0.75rem;
}

.zb-edit-item {
	margin: 0;
	padding: 0.15rem;
	background: #FFFFFF;
	/* box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3); */
	margin-bottom: 0.17rem;
	border-radius: 0.05rem;
}

.zb-edit-item-top {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: #9E9E9E;
	margin-bottom: 0.135rem;
}

.title-ltfe {
	font-size: 0.15rem;
	color: #343434;
	display: flex;
	align-items: center;
}

.title-ltfe>img {
	width: 0.18rem;
	display: block;
	margin-right: 0.1rem;
}

.title-right {
	font-size: 0.12rem;
}

.zb-edit-item-bottom {
	margin-bottom: 0.15rem;
	border-radius: 6px;
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
}

.zb-edit-item-bottom.textarea-box {
	margin-bottom: 0;
}

.zb-edit-item-bottom input {
	height: 0.5rem;
	width: 100%;
	outline: none;
	border-radius: 0.05rem;
	border: none;
	padding-left: 0.1rem;
	box-sizing: border-box;
	font-size: 0.14rem;
}

.zb-edit-item-bottom textarea {
	width: 100%;
	outline: none;

	border: none;
	padding: 0.1rem;
	box-sizing: border-box;
	resize: none;
	height: 0.76rem;
	font-size: 0.14rem;
	display: block;
	border-radius: 6px;
	font-family: 'AlibabaPuHuiTiBold';
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	/*WebKit browsers*/

	font-size: 0.14rem;

	color: #cbcbcb;
}

input::-moz-input-placeholder,
textarea::-moz-input-placeholder {
	/*Mozilla Firefox*/

	font-size: 0.14rem;

	color: #cbcbcb;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
	/*Internet Explorer*/

	font-size: 0.14rem;

	color: #cbcbcb;

}

.agree-box {
	display: flex;
	align-items: center;
}

.agree-box span {
	font-size: 0.14rem;
	font-weight: 500;
	letter-spacing: 0px;
	color: #9E9E9E;
	margin-left: 0.1rem
}

.submint-info {
	width: 100%;
	height: 0.5rem;
	line-height: 0.5rem;
	text-align: center;
	color: #FFFFFF;
	border-radius: 0.05rem;
	background: #2d71d7;
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.4);
	margin-top: 0.23rem;
	margin-bottom: 0.23rem;
	position: fixed;
	width: 85%;
	bottom: 0;
	left: 0;
	left: 50%;
	transform: translateX(-50%);
}

.err {
	font-size: 0.14rem;
	margin-top: 0.15rem;
	color: #ff5152;
}

.err1 {
	font-size: 0.14rem;
	margin-top: 0.15rem;
	margin-bottom: 0.15rem;
	color: #ff5152;
}</style>
